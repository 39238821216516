import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/home/index.vue";
import layout from "@/layout/index.vue"

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "layout",
		component: layout,
		children: [
			{
				path: "",
				name: "home",
				component: HomeView
			},
			{
				path: "register",
				name: "register",
				component: () => import(/* webpackChunkName: "register" */ "@/views/register/index.vue")
			},
			{
				path: "event",
				name: "event",
				component: () => import(/* webpackChunkName: "event" */ "@/views/event/index.vue")
			},
			{
				path: "event-form",
				name: "event-form",
				component: () => import(/* webpackChunkName: "event-form" */ "@/views/event/form.vue")
			},
			{
				path: "gallery",
				name: "gallery",
				component: () => import(/* webpackChunkName: "gallery" */ "@/views/gallery/index.vue")
			},
			{
				path: "gallery/album/:id",
				name: "gallery/album",
				component: () => import(/* webpackChunkName: "gallery/album" */ "@/views/gallery/album.vue")
			},
			{
				path: "sponsor",
				component: () => import(/* webpackChunkName: "sponsor" */ "@/views/sponsor/index.vue"),
				children: [
					{
						path: "",
						name: "sponsor",
						component: () => import(/* webpackChunkName: "sponsor" */ "@/views/sponsor/form.vue")
					},
					{
						path: "check",
						name: "sponsor_check",
						component: () => import(/* webpackChunkName: "sponsor_check" */ "@/views/sponsor/check.vue")
					}
				]
			},
		]
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
